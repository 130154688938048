<template>
  <div class="project pa-3">
    <project-table v-if="showProjects" />

    <project-profile v-else-if="showProject" />

    <internal-error v-else />

    <project-modal v-if="isUserInternalAdmin" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import mixin from '../components/mixin'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Project',
  components: {
    ProjectModal: () => import('../components/ProjectModal'),
    ProjectProfile: () => import('../components/Project'),
    ProjectTable: () => import('../components/Projects.vue'),
  },
  mixins: [mixin],
  computed: {
    ...mapAuthGetters(['isUserInternalAdmin']),
    ...mapGetters([
      'isReportIdValid',
    ]),
    ...mapToolsState({
      reportId: (state) => state.reportId,
    }),
    isReportIdInvalid() {
      const { isReportIdValid, reportId } = this
      return reportId && !isReportIdValid
    },
    showProjects() {
      const { isReportIdInvalid, reportId } = this
      return isReportIdInvalid || !reportId || reportId === 'dashboard'
    },
    showProject() {
      const { isReportIdInvalid, reportId } = this
      return !isReportIdInvalid && reportId
    },
  },
}
</script>
